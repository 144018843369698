<template>
    <div class="container-lg my-3 flex-fill body-container d-flex flex-column align-items-center" id="accueil">
        <div v-if="isFromTY" class="alert alert-primary container-lg d-flex align-items-center rounded-1" role="alert">
            <i class="fa-solid fa-check me-3"></i>

            <div class="-text-primary">Merci pour votre demande, vous allez recevoir un email de confirmation.</div>
        </div>

        <router-link to="/" class="btn btn-secondary btn-lg fs-2 fw-semibold mb-3"> Demander un devis </router-link>
        <img
            src="../assets/img/affiche2.svg"
            class="mw-100 shadow-lg border border-5 border-primary-light rounded"
            alt=""
        />
    </div>
</template>

<script>
import helpers from "@/services/helpers";

export default {
    created() {
        helpers.isBasicSection();
    },
    components: {},
    data() {
        let _isFromTY = localStorage.fromty && JSON.parse(localStorage.fromty);
        localStorage.fromty = false;

        return {
            isFromTY: _isFromTY,
        };
    },
};
</script>
