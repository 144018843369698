// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyAU62mQ7cDxUOBA9mn4PprErek0MCUqCY8",
    authDomain: "innov-events-dijon.firebaseapp.com",
    projectId: "innov-events-dijon",
    measurementId: "G-F019F62ZEP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const functions = getFunctions(app);
if (process.env.NODE_ENV == "development") {
    console.log("Functions : DEBUG_MODE");
    connectFunctionsEmulator(functions, "localhost", 5001);
}

export { functions };
