<template>
    <div class="container-lg my-3 flex-fill body-container d-flex flex-column align-items-center" id="accueil">
        <div v-if="isFromTY" class="alert bg-pastel-vert container-lg d-flex align-items-center rounded-1" role="alert">
            <i class="fa-solid fa-check me-3"></i>

            <div class="text-white" v-if="isConfirm">
                Merci pour votre inscription, un email de confirmation vous a été envoyé.
            </div>
            <div class="text-white" v-else>Merci, votre réponse a été prise en compte.</div>
        </div>

        <img
            src="../assets/img/affiche-inauguration.svg"
            class="mw-100 bg-white border border-5 border-pastel-orange rounded"
            alt=""
        />
    </div>
</template>

<script>
import helpers from "@/services/helpers";

export default {
    created() {
        helpers.isInaugurationSection();
    },
    components: {},
    data() {
        let _isFromTY = localStorage.fromty && JSON.parse(localStorage.fromty);
        localStorage.fromty = false;

        let _isConfirm = localStorage.p && JSON.parse(localStorage.p);
        localStorage.p = false;

        return {
            isFromTY: _isFromTY,
            isConfirm: _isConfirm,
        };
    },
};
</script>
