<template>
    <div class="inscription container-fluid mx-auto d-flex-center flex-column my-4 flex-fill body-container">
        <div v-if="isLoading" class="loader d-flex-center">
            <div class="d-flex-center flex-column bg-pastel-orange bg-opacity-75 rounded px-3 pt-4--5 pb-3">
                <i class="fa-solid fa-martini-glass-citrus text-white fa-bounce fa-2xl mb-4--5"></i>
                <strong class="text-white">Enregistrement de l'inscription</strong>
            </div>
        </div>

        <img src="../assets/img/logo.svg" class="inscription__logo mb-4" alt="" />

        <div class="bg-white px-3 py-4 shadow-lg bg-opacity-100 rounded-5 border border-pastel-orange border-4">
            <h1 class="w-100 bg-pastel-rouge fw-semibold text-uppercase rounded-4 mb-3 py-3">Formulaire de réponse</h1>

            <form method="POST" class="inscription__form needs-validation" novalidate>
                <div class="row g-3">
                    <!-- Participation -->
                    <div class="col-12 d-flex-center py-3">
                        <span class="fs-5">Je souhaite participer à l'événement :</span>
                        <button
                            type="button"
                            class="btn btn-lg mx-3 arial-rounded fw-bold"
                            v-bind:class="[
                                form_data.is_participating ? 'btn-pastel-orange' : 'btn-outline-pastel-orange',
                            ]"
                            @click="participate(true)"
                            for="chk_yes"
                        >
                            Oui
                        </button>
                        <button
                            type="button"
                            class="btn btn-lg arial-rounded fw-bold"
                            v-bind:class="[form_data.is_participating ? 'btn-outline-pastel-vert' : 'btn-pastel-vert']"
                            @click="participate(false)"
                            for="chk_no"
                        >
                            Non
                        </button>
                    </div>

                    <!-- Nom -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="text"
                                v-model="form_data.last_name"
                                id="last_name"
                                name="last_name"
                                placeholder="Dupond"
                                required
                            />
                            <label for="last_name">Nom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                        </div>
                    </div>

                    <!-- Prenom -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.first_name"
                                id="first_name"
                                name="first_name"
                                placeholder="Alain"
                                required
                            />
                            <label for="first_name">Prénom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre prénom</div>
                        </div>
                    </div>

                    <!-- Company name -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.company_name"
                                id="company_name"
                                name="company_name"
                                placeholder="Company Limited"
                                required
                            />
                            <label for="company_name">Entreprise*</label>
                            <div class="invalid-feedback">Veuillez renseigner le nom de votre entreprise</div>
                        </div>
                    </div>

                    <!-- Company role -->
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.company_role"
                                id="company_role"
                                name="company_role"
                                placeholder="Directeur"
                                required
                            />
                            <label for="company_role">Fonction*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre fonction</div>
                        </div>
                    </div>

                    <!-- Adresse email -->
                    <div class="col-12 col-sm-7">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="email"
                                v-model="form_data.email"
                                id="email_address"
                                name="email_address"
                                placeholder="nom@exemple.fr"
                                required
                            />
                            <label for="email_address">Adresse email*</label>
                            <div class="invalid-feedback">Adresse email invalide</div>
                        </div>
                    </div>

                    <!-- Telephone -->
                    <div class="col-12 col-sm-5">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="tel"
                                v-model="form_data.phone_number"
                                id="phone_number"
                                name="phone_number"
                                placeholder="0701020304"
                                required
                            />
                            <label for="phone_number">Numéro de téléphone*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre numéro de téléphone</div>
                        </div>
                    </div>

                    <!-- Accompagnement -->
                    <div class="col-12 ps-2" v-if="form_data.is_participating">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                v-model="form_data.is_accompanied"
                                type="checkbox"
                                id="is_accompanied"
                            />
                            <label class="form-check-label" for="is_accompanied">Je souhaite venir accompagné(e)</label>
                        </div>
                    </div>

                    <!-- Accompagnant -->
                    <div
                        v-if="form_data.is_accompanied && form_data.is_participating"
                        class="col-12 small text-pastel-orange text-uppercase"
                        style="letter-spacing: 1px"
                    >
                        Accompagnant(e)
                    </div>

                    <!-- Nom Accompagnant -->
                    <div v-if="form_data.is_accompanied && form_data.is_participating" class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="text"
                                v-model="form_data.mate_last_name"
                                id="mate_last_name"
                                name="mate_last_name"
                                placeholder="Dupont"
                                required
                            />
                            <label for="mate_name">Nom accompagnant(e)*</label>
                        </div>
                    </div>

                    <!-- Prenom Accompagnant -->
                    <div v-if="form_data.is_accompanied && form_data.is_participating" class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.mate_first_name"
                                id="mate_first_name"
                                name="mate_first_name"
                                placeholder="Alain"
                                required
                            />
                            <label for="mate_name">Prénom accompagnant(e)*</label>
                        </div>
                    </div>
                </div>

                <button
                    @click="sendRequest"
                    class="w-100 btn btn-pastel-vert arial-rounded rounded-4 btn-lg fs-3 fw-bold mt-3"
                    type="button"
                >
                    VALIDER
                </button>
                <div class="mt-2 small text-pastel-orange">* champs obligatoires</div>
            </form>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import helpers from "@/services/helpers";
const DuoEvent_SaveRegistration = httpsCallable(functions, "DuoEvent_SaveRegistration");

const empty_form = {
    last_name: "",
    first_name: "",
    company_name: "",
    company_role: "",
    email: "",
    phone_number: "",
    is_participating: true,
    is_accompanied: false,
    mate_first_name: "",
    mate_last_name: "",
};

export default {
    created() {
        this.cleanData();
        helpers.isInaugurationSection();
    },
    data() {
        return {
            isLoading: false,
            hasError: false,
            form_data: {
                is_participating: true,
                last_name: "Modin",
                first_name: "Damien",
                email: "damien@innov-events.fr",
                phone_number: "0789215568",
                company_name: "DMP EVENTS",
                company_role: "DG",
                is_accompanied: true,
                mate_first_name: "Jacques",
                mate_last_name: "Onpagne",
            },
        };
    },
    props: {},
    methods: {
        participate(is_participating) {
            this.form_data.is_participating = is_participating;
        },
        async sendRequest() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");

                if (form.checkValidity()) {
                    await DuoEvent_SaveRegistration(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                localStorage.p = this.form_data.is_participating;
                                this.cleanData();
                                router.push({ name: "done-inauguration" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form?.classList.remove("was-validated");
        },
    },
};
</script>
