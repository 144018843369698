<template>
    <div class="contact container-fluid mx-auto d-flex-center flex-column my-4 flex-fill body-container">
        <div v-if="isLoading" class="loader d-flex-center">
            <div class="d-flex-center flex-column bg-primary rounded px-3 pt-4--5 pb-3">
                <i class="fa-solid fa-martini-glass-citrus text-bluegrey fa-bounce fa-2xl mb-4--5"></i>
                <strong class="text-bluegrey">Envoi de la demande en cours...</strong>
            </div>
        </div>

        <img src="../assets/img/logo.svg" class="contact__logo mb-4" alt="" />

        <div class="bg-white p-3 shadow bg-opacity-100 rounded">
            <h1 class="w-100 bg-secondary fw-semibold text-uppercase rounded mb-3 py-3">Formulaire de contact</h1>

            <form method="POST" class="contact__form needs-validation" novalidate>
                <div class="row g-3">
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-uppercase"
                                type="text"
                                v-model="form_data.last_name"
                                id="last_name"
                                name="last_name"
                                placeholder="Dupond"
                                required
                            />
                            <label for="last_name">Nom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.first_name"
                                id="first_name"
                                name="first_name"
                                placeholder="Alain"
                                required
                            />
                            <label for="first_name">Prénom*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre prénom</div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.company_name"
                                id="company_name"
                                name="company_name"
                                placeholder="Company Limited"
                                required
                            />
                            <label for="company_name">Entreprise*</label>
                            <div class="invalid-feedback">Veuillez renseigner le nom de votre entreprise</div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control text-capitalize"
                                type="text"
                                v-model="form_data.company_role"
                                id="company_role"
                                name="company_role"
                                placeholder="Directeur"
                                required
                            />
                            <label for="company_role">Fonction*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre fonction</div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-7">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="email"
                                v-model="form_data.email"
                                id="email_address"
                                name="email_address"
                                placeholder="nom@exemple.fr"
                                required
                            />
                            <label for="email_address">Adresse email*</label>
                            <div class="invalid-feedback">Adresse email invalide</div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-5">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="tel"
                                v-model="form_data.phone_number"
                                id="phone_number"
                                name="phone_number"
                                placeholder="0701020304"
                                required
                            />
                            <label for="phone_number">Numéro de téléphone*</label>
                            <div class="invalid-feedback">Veuillez renseigner votre numéro de téléphone</div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="text"
                                v-model="form_data.event_date"
                                id="event_date"
                                name="event_date"
                                placeholder="Date(s) de l'évènement"
                                required
                            />
                            <label for="event_date">Date(s) de l'évènement*</label>
                            <div class="invalid-feedback">Veuillez renseigner la date de votre évènement</div>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="text"
                                v-model="form_data.nb_participants"
                                id="nb_participants"
                                name="nb_participants"
                                placeholder="50 personnes"
                                required
                            />
                            <label for="nb_participants">Nombre de participants*</label>
                            <div class="invalid-feedback">Veuillez renseigner le nombre de participants</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-floating">
                            <textarea
                                class="form-control"
                                placeholder="Laissez votre message ici"
                                id="message"
                                name="Message"
                                style="height: 150px"
                                v-model="form_data.description"
                                required
                            ></textarea>
                            <label for="description">Décrivez votre évènement*</label>
                            <div class="invalid-feedback">Veuillez décrire votre évènement</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="text"
                                v-model="form_data.budget"
                                id="budget"
                                name="budget"
                                placeholder=""
                                required
                            />
                            <label for="budget">Fourchette budgetaire*</label>
                            <div class="invalid-feedback">Veuillez renseigner une fourchette budgetaire</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="text-primary">Type de prestation recherchée :</div>
                    </div>

                    <div class="col-6" v-for="(presta, i) in form_data.prestations" v-bind:key="i">
                        <div class="form-check me-4">
                            <input
                                class="form-check-input"
                                v-bind:class="[presta.traiteur ? 'traiteur' : '']"
                                type="checkbox"
                                v-model="presta.value"
                                v-bind:id="`presta_${i}`"
                            />
                            <label class="form-check-label" v-bind:for="`presta_${i}`">{{ presta.name }}</label>
                        </div>
                    </div>

                    <div class="col-12 col-sm-6">
                        <div class="form-floating">
                            <input
                                class="form-control"
                                type="text"
                                v-model="form_data.autre_presta"
                                id="autre_presta"
                                name="autre_presta"
                                placeholder=""
                            />
                            <label for="autre_presta">Autre type de prestation</label>
                        </div>
                    </div>
                </div>

                <button @click="sendRequest" class="w-100 btn btn-primary btn-lg mt-3" type="button">Valider</button>
                <div class="mt-2 small text-secondary">* champs obligatoires</div>
            </form>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import helpers from "@/services/helpers";
const DuoEvent_SaveRequest = httpsCallable(functions, "DuoEvent_SaveRequest");

const empty_form = {
    last_name: "",
    first_name: "",
    company_name: "",
    company_role: "",
    email: "",
    phone_number: "",
    event_date: "",
    nb_participants: "",
    description: "",
    budget: "",
    autre_presta: "",
    prestations: [
        { name: "Prestation traiteur", value: false, traiteur: true },
        { name: "Plateaux repas", value: false, traiteur: true },
        { name: "Cocktail avec service ou sans service", value: false, traiteur: true },
        { name: "Café d'accueil", value: false, traiteur: true },
        { name: "Location de borne photos", value: false, traiteur: false },
        { name: "Gestion des transports", value: false, traiteur: false },
        { name: "Gestion des hébergements", value: false, traiteur: false },
        { name: "Organisation de mon séminaire", value: false, traiteur: false },
        { name: "Décoration", value: false, traiteur: false },
        { name: "Team-building", value: false, traiteur: false },
        { name: "Journée collaborateurs", value: false, traiteur: false },
        { name: "Animation", value: false, traiteur: false },
        { name: "Inspiration de fête de noël", value: false, traiteur: false },
    ],
};

export default {
    created() {
        this.cleanData();
        helpers.isBasicSection();
    },
    data() {
        return {
            isLoading: false,
            hasError: false,
            form_data: {
                last_name: "Modin",
                first_name: "Damien",
                email: "damien@innov-events.fr",
                phone_number: "0789215568",
                event_date: "12/03/2025",
                nb_participants: "75",
                description:
                    "Adipisicing officia ad ipsum reprehenderit non voluptate adipisicing exercitation eu cupidatat ut.",
                budget: "20K",
                autre_presta: "Garden Party",
                company_name: "DMP EVENTS",
                company_role: "DG",
                prestations: [
                    { name: "Prestation traiteur", value: true, traiteur: true },
                    { name: "Plateau repas", value: false, traiteur: true },
                    { name: "Cocktail avec service ou sans service", value: false, traiteur: true },
                    { name: "Café d'accueil", value: true, traiteur: true },
                    { name: "Location de borne photos", value: false, traiteur: false },
                    { name: "Gestion des transports", value: true, traiteur: false },
                    { name: "Gestion des hébergements", value: false, traiteur: false },
                    { name: "Organisation de mon séminaire", value: false, traiteur: false },
                    { name: "Décoration", value: true, traiteur: false },
                    { name: "Team-building", value: true, traiteur: false },
                    { name: "Journée collaborateurs", value: false, traiteur: false },
                    { name: "Animation", value: false, traiteur: true },
                    { name: "Inspiration de fête de noël", value: false, traiteur: false },
                ],
            },
        };
    },
    props: {},
    methods: {
        async sendRequest() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");

                if (form.checkValidity()) {
                    await DuoEvent_SaveRequest(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                this.cleanData();
                                router.push({ name: "done" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form?.classList.remove("was-validated");
        },
    },
};
</script>

<style lang="scss" scoped>
$primary: #f1deab;
$secondary: #d2e3c8;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $secondary, $alpha: 0.5);
}
</style>
