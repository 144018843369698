import Vue from "vue";
import App from "./App.vue";
import VueGtag from "vue-gtag";
import router from "./plugins/router";
import "./assets/styles/site.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "vanillajs-datepicker";

Vue.use(VueGtag, {
    config: { id: "G-F019F62ZEP" },
});

Vue.config.productionTip = false;

document.addEventListener("DOMContentLoaded", async function () {
    new Vue({
        router,
        render: (h) => h(App),
    }).$mount("#app");
});

